* {
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,
		sans-serif,Apple Color Emoji,Segoe UI Emoji;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
	font-feature-settings: kern;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html, body {
	height: 100%;
	width: 100%;
	background-color: #e9eeeb !important;
	--color-persistent-brand: #5e6ad2;
	--color-persistent-white: #fff;
    --color-text-error: #fd6b60;
	--color-text-success: #076e55;
}

#root {
	width: 100%;
	height: 100vh;
	overflow: auto;
}

.app {
	position: relative;
	margin: auto;
	justify-content: center;
	margin-top: 90px;
}

@media only screen and (min-width: 1340px) {
	.app {
		min-width: 1400px;
	}
}

@media only screen and (min-width: 600px) {
	.app {
		display: table;
		height: calc(100vh - 100px);
		max-width: 1500px;
	}
}

@media only screen and (min-width: 1600px) {
	.app {
		padding: 40px;
	}
}

/*
.dropzone {
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 50px 40px;
	display: flex;
	background-color: #fffeee;
	border: solid 1px #e0dda4;
	text-align: center;
}

.dropzone p {
	cursor: default;
}

.dropzone-transparent:focus {
    outline: 0;
}

.modal-close {
	position: absolute;
    right: 15px;
	top: 15px;
	cursor: pointer;
}
*/

button:focus,
audio:focus {
	outline: 0;
}

.mic-record-visualizer {
	border-radius: 8px;
}

.react-audio-element .controls {
	margin: 15px 0px;
}
.react-audio-element .time-text {
	font-size: 14px;
}

.CircularProgressbar.CircularProgressbar-custom .CircularProgressbar-background {
	fill: #fff;
}

.CircularProgressbar.CircularProgressbar-custom .CircularProgressbar-text {
	fill: #000;
}

.CircularProgressbar.CircularProgressbar-custom .CircularProgressbar-path {
	stroke: #5e6ad2;
}

.CircularProgressbar.CircularProgressbar-custom .CircularProgressbar-trail {
	stroke: transparent;
}

/* 
Will fix so the Chakra UI Modal overlay has bigger z-index 
than the popover which uses Tippy.js
*/
div[data-tippy-root] {
	z-index: 1000 !important;
}

button + button {
	margin-left: 12px;
}